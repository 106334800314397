<template>
  <div>
    <v-layout @click.stop="onClick" shrink>
      <SubviewCard
        :class="[
          'card-style',
        ]"
        :customShadow="{}"
      >
        <v-layout
          column
          align-center
          :class="[
            'miniature',
          ]"
        >
          <v-spacer></v-spacer>
          <v-layout shrink align-center>
            <v-layout column align-center v-if="!loading">
              <v-layout align-center shrink>
                <v-icon small class="mr-2 primary--text">mdi-plus</v-icon>
                <v-layout shrink>
                  <DefaultLabel>Dodaj ilustrację</DefaultLabel>
                </v-layout>
              </v-layout>
              <v-layout v-if="miniature" align-center column>
                <v-layout style="height: 114px" align-center justify-center>
                  <v-img
                    v-ripple
                    :src="miniature"
                    min-width="168"
                    max-width="168"
                  ></v-img>
                </v-layout>
                <DefaultSubText class="font-weight-bold" ellipsis>{{
                  name
                }}</DefaultSubText>
              </v-layout>
            </v-layout>
            <v-layout align-center v-else>
              <v-progress-circular
                color="primary"
                :size="dense ? 30 : 50"
                indeterminate
              >
              </v-progress-circular>
            </v-layout>
          </v-layout>
          <v-spacer></v-spacer>
        </v-layout>
      </SubviewCard>
    </v-layout>
    <EditorPopup
      v-if="showEditorPopup"
      :value.sync="showEditorPopup"
      :data="backgroundImage"
      :mode="editorMode"
      :title="`Dodaj - ${name}`"
      @save="onSave"
    ></EditorPopup>
    <SelectEditorBackgroundPopup
      v-if="showBackgroundPopup"
      :value.sync="showBackgroundPopup"
      @selected="handleSelectedBackground"
    ></SelectEditorBackgroundPopup>
  </div>
</template>

<script>
import ProxyCRUDMixin from "@/mixins/ProxyCRUDMixin";
import DrawingsService from "@/services/drawings.service";

const DRA = 1;
const SCARS = 2;
const VIS_CROTCH = 3;
const CROTCH = 4;

export default {
  mixins: [ProxyCRUDMixin],
  props: {
    owner: {},
    dense: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    single: {
      type: Boolean,
      default: false,
    },
    singleType: {
      type: Number,
      default: 0,
    },
    editorMode: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      backgroundImage: null,
      backgroundName: "",
      showBackgroundPopup: false,
      showEditorPopup: false,
      miniatureSrc: "",
    };
  },
  watch: {
    showEditorPopup() {
      this.$emit("editor", this.showEditorPopup);
    },
  },
  computed: {
    miniature() {
      if (this.singleType) {
        return require(`@/assets/patientcard/${this.singleType}.jpg`);
      } else {
        return "";
      }
    },
  },
  methods: {
    onClick() {
      if (this.owner) {
        return;
      }
      if (this.single) {
        this.fetchSource()
          .then((src) => this.downloadPicture(src))
          .then((file) => this.handleSelectedBackground(file));
      } else {
        this.handleBackgroundPopup();
      }
    },
    handleSelectedBackground(bg) {
      this.backgroundImage = bg;
      this.backgroundName = this.name;
      this.showEditorPopup = true;
    },
    handleBackgroundPopup() {
      this.showBackgroundPopup = true;
    },
    onSave(data) {
      this.$emit("new", { ...data, title: this.backgroundName });
    },
    async fetchSource() {
      return new Promise((resolve, reject) => {
        DrawingsService.getBackgrounds(this.singleType)
          .then((reply) => {
            resolve(reply[0].file);
          })
          .catch((error) => {
            console.log("ERROR", error, error.response);
            reject();
          });
      });
    },
    async downloadPicture(url) {
      return new Promise((resolve, reject) => {
        this.beforeRequest();
        DrawingsService.downloadPicture(process.env.VUE_APP_URL_BACKEND + url)
          .then((reply) => {
            let blob = new Blob([reply], { type: "image/svg+xml" });
            this.reset();
            resolve(blob);
          })
          .catch((error) => {
            this.reset();
            reject(error);
          });
      });
    },
  },
  components: {
    SelectEditorBackgroundPopup: () =>
      import("@/components/popups/drawings/SelectEditorBackgroundPopup"),
    EditorPopup: () => import("@/components/popups/drawings/EditorPopup"),
    SubviewCard: () => import("@/components/cards/SubviewCard"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
    DefaultSubText: () => import("@/components/text/DefaultSubText"),
  },
};
</script>

<style scoped lang="scss">
.card-style {
  border-radius: 0.5rem !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--v-border-base) !important;
  background-color: white !important;
  width: 200px !important;
  cursor: pointer;
  height: 190px !important;
}
.miniature {
  height: 148px !important;
}
</style>